import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllRegions: { args: ["payload"], meta: { async: true } },
    getRegionWithId: { args: ["payload"], meta: { async: true } },
    addRegion: { args: ["payload"], meta: { async: true } },
    editRegion: { args: ["payload"], meta: { async: true } },
    deleteRegion: { args: ["payload"], meta: { async: true } },

    setRegion: { args: ["payload"], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    showDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/regions/",
  }
);
export const ActionTypes = Types;
export default Creators;
