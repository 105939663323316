import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("regions", { metadata: undefined }, []),
};

const regionCreatedSuccess = (state, draft, payload) => {
  const regions = state.regions || [];
  draft.regions = [...regions, payload];
  draft.openDialog = false;
};

const editRegionSuccess = (state, draft, payload) => {
  const index = state.regions.findIndex((r) => r.id === payload.id);
  if (index >= 0) {
    draft.regions[index] = payload;
  }
  draft.openDialog = false;
};

const regionDeleteSuccess = (state, draft, payload) => {
  const regions = state.regions || [];
  draft.regions = regions.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};

const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};

const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};

const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllRegions",
    stateName: "regions",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getRegionWithId",
    stateName: "region",
    async: true,
    isPaginated: false,
  }),

  ...bindReducers(ActionTypes, {
    action: "addRegion",
    stateName: "region",
    async: true,
    isPaginated: false,
    successCb: regionCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "editRegion",
    stateName: "region",
    async: true,
    isPaginated: false,
    successCb: editRegionSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteRegion",
    stateName: "region",
    async: true,
    isPaginated: false,
    successCb: regionDeleteSuccess,
  }),

  ...bindReducers(ActionTypes, {
    action: "setRegion",
    stateName: "region",
    async: false,
    isPaginated: false,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
