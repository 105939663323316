export default function (/**@type {ApisauceInstance} */ api) {
  const getAllAreas = (paging) => api.get(`/Area`, paging);
  const getAreaWithId = (id) => api.get(`/Area/${id}`);
  const addArea = (payload) => api.post(`/Area/add-area`, payload);
  const editArea = ({ payload, id }) =>
    api.put(`/Area/edit-area/${id}`, payload);
  const deleteArea = (id) => api.delete(`/Area/delete-area/${id}`);

  return {
    areas: {
      getAllAreas,
      getAreaWithId,
      addArea,
      editArea,
      deleteArea,
    },
  };
}
