import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllAreas: { args: ["payload"], meta: { async: true } },
    getAreaWithId: { args: ["payload"], meta: { async: true } },
    addArea: { args: ["payload"], meta: { async: true } },
    editArea: { args: ["payload"], meta: { async: true } },
    deleteArea: { args: ["payload"], meta: { async: true } },

    setArea: { args: ["payload"], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    showDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/areas/",
  }
);
export const ActionTypes = Types;
export default Creators;
