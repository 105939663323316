export default function (/**@type {ApisauceInstance} */ api) {
  const getAllRegions = (paging) => api.get(`/Region`, paging);
  const getRegionWithId = (id) => api.get(`/Region/${id}`);
  const addRegion = (payload) => api.post(`/Region/add-region`, payload);
  const editRegion = ({ payload, id }) =>
    api.put(`/Region/edit-region/${id}`, payload);
  const deleteRegion = (id) => api.delete(`/Region/delete-region/${id}`);

  return {
    regions: {
      getAllRegions,
      getRegionWithId,
      addRegion,
      editRegion,
      deleteRegion,
    },
  };
}
