import apisauce from "apisauce";
import config from "../configs";
import authApi from "./auth";
import usersApi from "./userManagement";
import settingsApi from "./setting";

//---------------------

import productsApi from "./products";
import brandsApi from "./brands";
import offersApi from "./offers";
import outletApi from "./outlets";
import companyInfoApi from "./companyInfo";
import lookupsApi from "./lookups";
import prizeApi from "./prize";
import cityApi from "./city";
import NationalityApi from "./nationalities";
import ageRangeApi from "./ageRange";
import promoterApi from "./promoter";
import supervisorApi from "./supervisors";
import reportsApi from "./reports";
import surveysApi from "./survey";
import dashboardApi from "./dashboard";
import refusalReasonApi from "./refusalReason";
import shiftSessionApi from "./shiftSession";
import outletTypeApi from "./outletType";
import channelApi from "./channel";
import groupApi from "./group";
import regionApi from "./region";
import areaApi from "./area";

import configs from "src/configs";
import { useSelector } from "react-redux";
// const apiURI = config.useLocalApi ? config.devApiRoot : config.apiRoot;
// const apiURI = configs.REACT_APP_BASE_API_URL;

let api = null;

const create = (baseURL) => {
  let app = localStorage.getItem("app_name");

  let apiURI =
    app === "roi"
      ? process.env.REACT_APP_BASE_API_URL
      : process.env.REACT_APP_BASE_API_URL_NAKHLA;

  api = apisauce.create({
    baseURL: apiURI,
    headers: {
      "Cache-Control": "no-cache",
    },
    timeout: 1200000, //30 sec
  });
  //add access token on each request

  api.addAsyncResponseTransform((response) => async () => {
    if (!response.ok) {
      const customeError = {
        errors: response.data?.errors || [
          { errorMessage: response.data?.errorMessage || "" },
        ],
        // title: response.data.title,
        errorMessage: response.data?.errorMessage || "",
        // details: response.data.details,
        // data: response.data.result,
        status: response.status,
        code: response.data?.errorCode || response.status,
      };
      response.data = customeError;
    }
  });

  api.addAsyncRequestTransform((request) => async () => {
    const token = localStorage.getItem("jwtToken");

    const culture = localStorage.getItem("culture");
    const cultureCode = localStorage.getItem("cultureCode");
    const userIp = localStorage.getItem("userIp");

    request.headers["culture"] = culture || "en";
    request.headers["cultureCode"] = cultureCode || "en-US";
    request.headers["userIp"] = userIp || "";

    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    } else {
      delete request.headers["Authorization"];
    }
  });

  const auth = authApi(api);
  const users = usersApi(api);
  const settings = settingsApi(api);
  // --------------------
  const products = productsApi(api);
  const outlets = outletApi(api);
  const brands = brandsApi(api);
  const offers = offersApi(api);
  const companyInfos = companyInfoApi(api);
  const lookups = lookupsApi(api);
  const prizes = prizeApi(api);
  const cities = cityApi(api);
  const nationalities = NationalityApi(api);
  const ageRanges = ageRangeApi(api);
  const promoters = promoterApi(api);
  const supervisors = supervisorApi(api);
  const reports = reportsApi(api);
  const surveys = surveysApi(api);
  const dashboards = dashboardApi(api);
  const refusalReasons = refusalReasonApi(api);
  const shiftSessions = shiftSessionApi(api);
  const outletTypes = outletTypeApi(api);
  const channels = channelApi(api);
  const group = groupApi(api);
  const region = regionApi(api);
  const area = areaApi(api);

  // const transactions = transactionsApi(api);
  return {
    setHeader: api.setHeader,
    setHeaders: api.setHeaders,
    ...auth,
    ...users,
    ...settings,
    ...brands,
    ...products,
    ...offers,
    ...companyInfos,
    ...lookups,
    ...prizes,
    ...outlets,
    ...cities,
    ...nationalities,
    ...ageRanges,
    ...promoters,
    ...reports,
    ...surveys,
    ...supervisors,
    ...reports,
    ...dashboards,
    ...refusalReasons,
    ...shiftSessions,
    ...outletTypes,
    ...channels,
    ...group,
    ...region,
    ...area,
    setBaseURL: api?.setBaseURL,
  };
};

export default { create };
