import appLogic from "./app/logic";
import authLogic from "./auth/logic";
import usersLogic from "./userManagement/logic";
import settingLogic from "./setting/logic";
//---------------------------
import offersLogic from "./offers/logic";
import productsLogic from "./products/logic";
import brandsLogic from "./brands/logic";
import companyInfoLogic from "./companyInfo/logic";
import lookupsLogic from "./lookups/logic";
import prizeLogic from "./prize/logic";
import outletsLogic from "./outlets/logic";
import cityLogic from "./city/logic";
import nationalityLogic from "./nationalities/logic";
import ageRangeLogic from "./ageRange/logic";
import promoterLogic from "./promoter/logic";
import supervisorLogic from "./supervisor/logic";
import reportsLogic from "./reports/logic";
import surveysLogic from "./survey/logic";
import dashboardLogic from "./dashboard/logic";
import refusalReasonLogic from "./refusalReason/logic";
import shiftSessionLogic from "./shiftSessions/logic";
import outletTypeLogic from "./outletType/logic";
import channelLogic from "./channel/logic";
import groupLogic from "./group/logic";
import regionLogic from "./region/logic";
import areaLogic from "./area/logic";

export default [
  ...appLogic,
  ...authLogic,
  ...usersLogic,
  ...settingLogic,
  ...brandsLogic,
  ...productsLogic,
  ...lookupsLogic,
  ...companyInfoLogic,
  ...prizeLogic,
  ...offersLogic,
  ...outletsLogic,
  ...cityLogic,
  ...nationalityLogic,
  ...ageRangeLogic,
  ...promoterLogic,
  ...supervisorLogic,
  ...reportsLogic,
  ...surveysLogic,
  ...dashboardLogic,
  ...refusalReasonLogic,
  ...shiftSessionLogic,
  ...outletTypeLogic,
  ...channelLogic,
  ...groupLogic,
  ...regionLogic,
  ...areaLogic,
];
