import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("areas", { metadata: undefined }, []),
};

const areaCreatedSuccess = (state, draft, payload) => {
  const areas = state.areas || [];
  draft.areas = [...areas, payload];
  draft.openDialog = false;
};

const editAreaSuccess = (state, draft, payload) => {
  const index = state.areas.findIndex((r) => r.id === payload.id);
  if (index >= 0) {
    draft.areas[index] = payload;
  }
  draft.openDialog = false;
};

const areaDeleteSuccess = (state, draft, payload) => {
  const areas = state.areas || [];
  draft.areas = areas.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};

const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};

const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};

const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllAreas",
    stateName: "areas",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getAreaWithId",
    stateName: "area",
    async: true,
    isPaginated: false,
  }),

  ...bindReducers(ActionTypes, {
    action: "addArea",
    stateName: "area",
    async: true,
    isPaginated: false,
    successCb: areaCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "editArea",
    stateName: "area",
    async: true,
    isPaginated: false,
    successCb: editAreaSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteArea",
    stateName: "area",
    async: true,
    isPaginated: false,
    successCb: areaDeleteSuccess,
  }),

  ...bindReducers(ActionTypes, {
    action: "setArea",
    stateName: "area",
    async: false,
    isPaginated: false,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
