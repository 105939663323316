import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";
import { Trans } from "@lingui/react";

const apiNamespace = "regions";

const getAllRegionsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_REGIONS,
});
const getRegionWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_REGION_WITH_ID,
});

const addRegionLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_REGION,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/region/list"));
  },
});

const editRegionLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_REGION,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/region/list"));
  },
});

const deleteRegionLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_REGION,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  getAllRegionsLogic,
  getRegionWithIdLogic,
  addRegionLogic,
  editRegionLogic,
  deleteRegionLogic,
];
