import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";
import { Trans } from "@lingui/react";

const apiNamespace = "areas";

const getAllAreasLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_AREAS,
});
const getAreaWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_AREA_WITH_ID,
});

const addAreaLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_AREA,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/area/list"));
  },
});

const editAreaLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_AREA,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/area/list"));
  },
});

const deleteAreaLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_AREA,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  getAllAreasLogic,
  getAreaWithIdLogic,
  addAreaLogic,
  editAreaLogic,
  deleteAreaLogic,
];
